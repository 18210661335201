exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-bajecna-studentska-leta-jsx": () => import("./../../../src/pages/blog/bajecna-studentska-leta.jsx" /* webpackChunkName: "component---src-pages-blog-bajecna-studentska-leta-jsx" */),
  "component---src-pages-blog-cena-kterou-platime-za-chaoticke-nakupovani-jsx": () => import("./../../../src/pages/blog/cena-kterou-platime-za-chaoticke-nakupovani.jsx" /* webpackChunkName: "component---src-pages-blog-cena-kterou-platime-za-chaoticke-nakupovani-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-jak-flagis-usnadnuje-spravu-ukolu-a-komunikaci-jsx": () => import("./../../../src/pages/blog/jak-flagis-usnadnuje-spravu-ukolu-a-komunikaci.jsx" /* webpackChunkName: "component---src-pages-blog-jak-flagis-usnadnuje-spravu-ukolu-a-komunikaci-jsx" */),
  "component---src-pages-blog-jak-jednoduse-zorganizovat-rodinny-zivot-jsx": () => import("./../../../src/pages/blog/jak-jednoduse-zorganizovat-rodinny-zivot.jsx" /* webpackChunkName: "component---src-pages-blog-jak-jednoduse-zorganizovat-rodinny-zivot-jsx" */),
  "component---src-pages-blog-jak-prezit-stavbu-domu-jsx": () => import("./../../../src/pages/blog/jak-prezit-stavbu-domu.jsx" /* webpackChunkName: "component---src-pages-blog-jak-prezit-stavbu-domu-jsx" */),
  "component---src-pages-blog-jak-si-nejlepe-naplanovat-a-uzit-dovolenou-jsx": () => import("./../../../src/pages/blog/jak-si-nejlepe-naplanovat-a-uzit-dovolenou.jsx" /* webpackChunkName: "component---src-pages-blog-jak-si-nejlepe-naplanovat-a-uzit-dovolenou-jsx" */),
  "component---src-pages-blog-jak-si-v-pohode-uzit-prosinec-jsx": () => import("./../../../src/pages/blog/jak-si-v-pohode-uzit-prosinec.jsx" /* webpackChunkName: "component---src-pages-blog-jak-si-v-pohode-uzit-prosinec-jsx" */),
  "component---src-pages-blog-jak-to-vsechno-zvlada-jsx": () => import("./../../../src/pages/blog/jak-to-vsechno-zvlada.jsx" /* webpackChunkName: "component---src-pages-blog-jak-to-vsechno-zvlada-jsx" */),
  "component---src-pages-blog-kdo-si-zvladne-zorganizovat-cas-vyhrava-jsx": () => import("./../../../src/pages/blog/kdo-si-zvladne-zorganizovat-cas-vyhrava.jsx" /* webpackChunkName: "component---src-pages-blog-kdo-si-zvladne-zorganizovat-cas-vyhrava-jsx" */),
  "component---src-pages-blog-kdo-slavi-konec-roku-v-breznu-jsx": () => import("./../../../src/pages/blog/kdo-slavi-konec-roku-v-breznu.jsx" /* webpackChunkName: "component---src-pages-blog-kdo-slavi-konec-roku-v-breznu-jsx" */),
  "component---src-pages-blog-kontakty-a-spoluprace-s-ostatnimi-jsx": () => import("./../../../src/pages/blog/kontakty-a-spoluprace-s-ostatnimi.jsx" /* webpackChunkName: "component---src-pages-blog-kontakty-a-spoluprace-s-ostatnimi-jsx" */),
  "component---src-pages-blog-nastaveni-uzivatelskeho-prostredi-aplikace-jsx": () => import("./../../../src/pages/blog/nastaveni-uzivatelskeho-prostredi-aplikace.jsx" /* webpackChunkName: "component---src-pages-blog-nastaveni-uzivatelskeho-prostredi-aplikace-jsx" */),
  "component---src-pages-blog-nekdy-neni-dobre-hasit-jen-to-co-hori-jsx": () => import("./../../../src/pages/blog/nekdy-neni-dobre-hasit-jen-to-co-hori.jsx" /* webpackChunkName: "component---src-pages-blog-nekdy-neni-dobre-hasit-jen-to-co-hori-jsx" */),
  "component---src-pages-blog-pet-duvodu-proc-se-vlastne-netesite-na-dovolenou-jsx": () => import("./../../../src/pages/blog/pet-duvodu-proc-se-vlastne-netesite-na-dovolenou.jsx" /* webpackChunkName: "component---src-pages-blog-pet-duvodu-proc-se-vlastne-netesite-na-dovolenou-jsx" */),
  "component---src-pages-blog-pet-kroku-k-lepsi-efektivite-jsx": () => import("./../../../src/pages/blog/pet-kroku-k-lepsi-efektivite.jsx" /* webpackChunkName: "component---src-pages-blog-pet-kroku-k-lepsi-efektivite-jsx" */),
  "component---src-pages-blog-pet-mytu-o-novorocnich-predsevzetich-jsx": () => import("./../../../src/pages/blog/pet-mytu-o-novorocnich-predsevzetich.jsx" /* webpackChunkName: "component---src-pages-blog-pet-mytu-o-novorocnich-predsevzetich-jsx" */),
  "component---src-pages-blog-pridavani-ukolu-a-stitku-jsx": () => import("./../../../src/pages/blog/pridavani-ukolu-a-stitku.jsx" /* webpackChunkName: "component---src-pages-blog-pridavani-ukolu-a-stitku-jsx" */),
  "component---src-pages-blog-proc-jsou-zeny-porad-ustvane-jsx": () => import("./../../../src/pages/blog/proc-jsou-zeny-porad-ustvane.jsx" /* webpackChunkName: "component---src-pages-blog-proc-jsou-zeny-porad-ustvane-jsx" */),
  "component---src-pages-blog-proc-potrebujeme-system-jsx": () => import("./../../../src/pages/blog/proc-potrebujeme-system.jsx" /* webpackChunkName: "component---src-pages-blog-proc-potrebujeme-system-jsx" */),
  "component---src-pages-blog-samolepici-papirky-diar-nebo-chytra-appka-jsx": () => import("./../../../src/pages/blog/samolepici-papirky-diar-nebo-chytra-appka.jsx" /* webpackChunkName: "component---src-pages-blog-samolepici-papirky-diar-nebo-chytra-appka-jsx" */),
  "component---src-pages-blog-spasi-nas-kultura-jsx": () => import("./../../../src/pages/blog/spasi-nas-kultura.jsx" /* webpackChunkName: "component---src-pages-blog-spasi-nas-kultura-jsx" */),
  "component---src-pages-blog-stastne-vesele-a-dobre-organizovane-jsx": () => import("./../../../src/pages/blog/stastne-vesele-a-dobre-organizovane.jsx" /* webpackChunkName: "component---src-pages-blog-stastne-vesele-a-dobre-organizovane-jsx" */),
  "component---src-pages-blog-tridte-ukoly-podle-odhadovaneho-casu-jsx": () => import("./../../../src/pages/blog/tridte-ukoly-podle-odhadovaneho-casu.jsx" /* webpackChunkName: "component---src-pages-blog-tridte-ukoly-podle-odhadovaneho-casu-jsx" */),
  "component---src-pages-blog-vyhledavani-a-filtrovani-ukolu-jsx": () => import("./../../../src/pages/blog/vyhledavani-a-filtrovani-ukolu.jsx" /* webpackChunkName: "component---src-pages-blog-vyhledavani-a-filtrovani-ukolu-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-features-clear-responsibility-jsx": () => import("./../../../src/pages/features/clear-responsibility.jsx" /* webpackChunkName: "component---src-pages-features-clear-responsibility-jsx" */),
  "component---src-pages-features-simplicity-jsx": () => import("./../../../src/pages/features/simplicity.jsx" /* webpackChunkName: "component---src-pages-features-simplicity-jsx" */),
  "component---src-pages-features-smart-view-jsx": () => import("./../../../src/pages/features/smart-view.jsx" /* webpackChunkName: "component---src-pages-features-smart-view-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-cookies-policy-jsx": () => import("./../../../src/pages/legal/cookies-policy.jsx" /* webpackChunkName: "component---src-pages-legal-cookies-policy-jsx" */),
  "component---src-pages-legal-disclaimer-jsx": () => import("./../../../src/pages/legal/disclaimer.jsx" /* webpackChunkName: "component---src-pages-legal-disclaimer-jsx" */),
  "component---src-pages-legal-eula-jsx": () => import("./../../../src/pages/legal/eula.jsx" /* webpackChunkName: "component---src-pages-legal-eula-jsx" */),
  "component---src-pages-legal-privacy-policy-jsx": () => import("./../../../src/pages/legal/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-jsx" */),
  "component---src-pages-legal-terms-of-use-jsx": () => import("./../../../src/pages/legal/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-legal-terms-of-use-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-product-tips-jsx": () => import("./../../../src/pages/product/tips.jsx" /* webpackChunkName: "component---src-pages-product-tips-jsx" */),
  "component---src-pages-video-tutorials-jsx": () => import("./../../../src/pages/video-tutorials.jsx" /* webpackChunkName: "component---src-pages-video-tutorials-jsx" */)
}

